import { render, staticRenderFns } from "./Error.vue?vue&type=template&id=2583b0b5&scoped=true&"
import script from "./Error.vue?vue&type=script&lang=ts&"
export * from "./Error.vue?vue&type=script&lang=ts&"
import style0 from "./Error.vue?vue&type=style&index=0&id=2583b0b5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2583b0b5",
  null
  
)

export default component.exports
import {QIcon} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QIcon})
